<template>
  <div class="logo">
    <p class="hipp"><span>H</span><span>I</span><span>P</span><span>P</span></p>
    <img src="@/assets/img/logo-img-trait.png" />
    <p class="campe"><span>C</span><span>A</span><span>M</span><span>P</span><span>E</span></p>
    <p class="years"><span class="year">{{year}}</span> ans</p>
    <a class="link" href="https://hippocampe.fr" target="_blank" onclick="window._paq.push(['trackEvent', 'CTA', 'Click CTA', 'Click CTA Hippocampe']);">La suite ici !</a>
  </div>
</template>

<script>
import gsap from "gsap";
import Utils from "@/utils/Utils";

export default {
  name: "Logo",
  props: ['year'],
  data() {
    return {
      lastScroll: 0
    }
  },
  mounted() {
    let link = document.querySelector('.link')
    link.innerHTML = Utils.spanSplitLetter(link.innerHTML)
    let logo = document.querySelector('.logo .years')
    let yearDiv = document.querySelector('.logo .year')
    let img = document.querySelector('.logo img')

    if (window.scrollY > 0) {
      smaller()
    }

    function bigger() {
      //el.id = `tmp`
      window.location.hash = "joyeux-anniversaire-!!"
      //el.id = tmp
      document.querySelectorAll('.hipp, .campe').forEach(el => {
        el.classList.remove('hide')
      })
      img.classList.remove('return')
      logo.classList.remove('small')
      yearDiv.classList.remove('big')
      gsap.to('.logo', {scale: 0.7, y: '-15%', duration: 1})
      let year = {val: parseInt(yearDiv.innerHTML)}

      gsap.to(year, {val: 25, roundProps: 'val', duration: 0.5, onUpdate: () => {
          let prefix = year.val < 10 ? '0' : ''
          yearDiv.innerHTML = prefix + year.val
      }})
    }

    function smaller() {
      link.classList.remove('show')
      img.classList.add('return')
      logo.classList.add('small')
      yearDiv.classList.add('big')
      document.querySelectorAll('.hipp, .campe').forEach(el => {
          el.classList.add('hide')
      })
      gsap.to('.logo', {scale: 0.3, y: '-43%', duration: 1})
    }
    window.addEventListener('scroll', () => {
      if (window.scrollY < (window.innerHeight / 2)) {
        if (window.scrollY - this.lastScroll > 0) {
          // scroll vers le bas
        smaller()
        } else {
          // scroll vers le haut
          bigger()
        }
        this.lastScroll = window.scrollY
      } else if (window.scrollY >= document.body.scrollHeight - (window.innerHeight * 1.5)) {
        link.classList.add('show')
        bigger()
      } else {
          smaller()
      }
    })
  }
};
</script>

<style lang="scss">
.logo {
  z-index: 100;
  opacity: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: calc(50% - 320px);
  width: 640px;
  height: 100vh;

  margin: auto;
  img {
    height: 60%;
    transform: translate(7%, 0px) scale(1, 1);
    transition: 1s;

    &.return {
      transform: translate(95%, 0px) scale(-0.6, 0.6);
    }
  }
  .hipp, .campe {
    color: black;
    font-size: 6em;

    span {
      color: black;
    }
    @for $i from 1 through 5 {
        span:nth-child(#{$i}n) {
            transition-duration: .3s;
            transition-delay: #{$i * 0.05}s;
        }
    }

    &.hide {
      span {
        opacity: 0;
      }
    }
  }

  .hipp {
    transform: translate(7%, -35%);

    &.hide {
      span {
        transform: translateX(-35%);
      }
    }
  }

  .campe {
    transform: translate(-7%, -35%);

    &.hide {
      span {
        transform: translateX(35%);
      }
    }
  }

  .years {
    position: absolute;
    right: 0%;
    bottom: 35%;

    color: $white;
    font-size: 4rem;
    font-weight: bold;

    transform: translateX(0%);

    transition: 1s;

    &.small {
      transform: translate(-150%, 50%);
    }
  }

  .year{
    transition: 1s;
    &.big {
    transform: scale(5) translate(6px, -22px);
    opacity: 0.4;
    display: inline-block;
  } 
  }

  .link {
    display: none;
    opacity: 0;
    position: absolute;
    bottom: 0;
    font-size: 3em;
    font-weight: bold;

    transition: 1s;

    span {
      display: inline-block;
      animation: floated ease-in-out infinite both alternate;
      animation-duration: 3s;
    }
    @for $i from 1 through 10 {
        span:nth-child(#{$i}n) {
            transition-duration: .3s;
            animation-delay: #{$i * -0.8}s;
        }
    }

    &.show {
      display: block;
      opacity: 1;
    }

    &:hover span {
      animation-duration: 0.3s;
    }
  }

  @media (max-width: 600px) {
      left: calc(50% - 150px);
      width: 300px;

      .years {
          bottom: 42%;
          font-size: 2.5rem;
      }

      img{
        height: 30%;
      }

      .hipp, .campe {
        font-size: 3em;
      }
  }
}

@keyframes floated {
  from {
    transform: translateY(10px);
  } to {
    transform: translateY(-10px);
  }
}
</style>
