<template>
    <div id="three-navigation">
        <!-- <canvas id="stage"></canvas> -->
        <div id="links">
            <Element v-for="(data, i) in datas" :data="data" :pos="i" :key="i"/>
        </div>
    </div>
</template>

<script>
import Utils from '@/utils/Utils'
import {Scene, Clock, PerspectiveCamera, WebGLRenderer} from 'three'
import lerp from 'lerp'
import ElementThree from '../classes/Element'
import Element from '../components/Element'
import datas from '../elementsData.js'

let BODY = document.body.getBoundingClientRect()
let mouse = {x: 0, y: 0}
let scroll = {top: 0, last: 0}
let imageHovered = false
let imageHoveredTimeout = null

let scene = new Scene(),
camera = new PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 4.5, 5.5 ),
renderer,
clock = new Clock();

camera.position.z = 5
camera.fov = 2*(180/Math.PI)*Math.atan(1/(2*5));

let elements = []


export default {
    name: 'ThreeNavigation',
    components: {Element},
    data() {
        return {
            datas: datas
        }
    },
    mounted() {
        // renderer = new WebGLRenderer ({ alpha: true, antialias: false, canvas: document.querySelector("#stage") })
        // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


        document.querySelectorAll('.element').forEach(el => {
            let titleClass = '#titles .' + el.classList[1]
            el.querySelector(".element-title").innerHTML = Utils.spanSplit(el.querySelector(".element-title").innerHTML)
            el.querySelector(".element-date").innerHTML = Utils.spanSplit(el.querySelector(".element-date").innerHTML)
            el.querySelector(".element-desc").innerHTML = Utils.spanSplit(el.querySelector(".element-desc").innerHTML)
            // let THREE = new ElementThree(el.querySelector('.element-img'))
            // scene.add(THREE.img.mesh)
            // elements.push(THREE)
        });

        // window.addEventListener( 'resize', this.onWindowResize, false );

        // this.animate();

        // window.addEventListener('load', this.onWindowResize, false)
        // document.body.addEventListener('mousemove', this.updateMouse)
        // window.addEventListener('scroll', this.updateScroll)
        // this.onWindowResize()
    },
    updated() {
        // this.onWindowResize()
    },
    methods: {
        animate() {

            // requestAnimationFrame( this.animate );

            // for(let i = 0; i < elements.length; i++) {
            //     let odd
            //     i % 2 == 0 ? odd = 1 : odd = -1
            //     elements[i].img.material.uniforms.uTime.value = ((clock.getElapsedTime() + (i *5000)) * 0.2) * odd;
            //     // let offset = {x: elements[i].dimensions.x + window.scrollX, y: elements[i].dimensions.y + window.scrollY}
            //     // elements[i].material.uniforms.mousePosition.value = new Vector3(mouse.x + offset.x, mouse.y + offset.y, Math.sqrt(Math.pow(mouse.x - offset.x ,2) + Math.pow(mouse.y - offset.y ,2)))
            //     elements[i].img.material.uniforms.scale.value = Utils.mapInversed(scroll.top, 0, elements[i].img.dimensions.y, 1, 0)
            //     elements[i].img.material.uniforms.shift.value = lerp(elements[i].img.material.uniforms.shift.value, (scroll.top - scroll.last) / 150, 0.1)
            //     if (i == elements.length - 1) {
            //         scroll.last = scroll.top
            //     }


            //     //this.updateImagePositions()

            // }
            //renderer.render( scene, camera );
        },
        updateImagePositions() {
                let imgs = document.querySelectorAll('.element-img')
                for (let i = 0; i < imgs.length; i++) {
                    elements[i].img.updatePos(imgs[i], BODY, camera);
                }
        },
        onWindowResize(){
                BODY = document.body.getBoundingClientRect()

                camera.aspect = BODY.width / BODY.height;
                camera.fov = 2*(180/Math.PI)*Math.atan(1/(2*5));
                camera.updateProjectionMatrix();
    
                renderer.setSize( BODY.width, BODY.height );

                this.updateImagePositions()
        },
        updateMouse(e) {
            mouse = {x: e.x + window.scrollX, y: e.y + window.scrollY}
        },
        updateScroll(e) {
            scroll.top = window.scrollY
        },
        scrollParalaxe() {
            
        }
    }
}
</script>

<style lang="scss">
 #three-navigation {
     //position: relative;
     width: 100%;

     overflow: hidden;

     canvas {
        display: block; 
        position: absolute;
        z-index: 1; // put it to background
        left: 0; // position it to fill the whole screen
        top: 0; // position it to fill the whole screen
     }

     #links {
         position: relative;
     }
 }
</style>