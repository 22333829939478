export default `
uniform sampler2D textureCustom;
uniform float shift;
uniform float scale;
uniform float opacity;
varying vec2 vUv;
void main() {
  float angle = 1.55;
  vec2 p = (vUv - vec2(0.5, 0.5)) * (1.0 - scale) + vec2(0.5, 0.5);
  vec2 offset = shift / 4.0 * vec2(cos(angle), sin(angle));
  vec4 cr = texture(textureCustom, p);
  vec4 cga = texture(textureCustom, p);
  vec4 cb = texture(textureCustom, p);
  gl_FragColor = vec4(cr.r, cga.g, cb.b, cga.a);
}`