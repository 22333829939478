<template>
    <nav class="timeline">
        <ul>
            <li v-for="(data, i) in datas" :key="i"><a :href="'#'+data.date" @click.prevent="scrolling(i)"><span class="line hide"></span><span class="date">{{ data.date }}</span></a></li>
        </ul>
    </nav>
</template>

<script>
import datas from '../elementsData.js'

export default {
    name: 'Timeline',
    data() {
        return {
            datas: datas
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            let active = false
            document.querySelectorAll('.element').forEach(el => {
                if (el.classList.contains('show')) {
                    active = true
                    let tmp = el.id
                    el.id = `tmp`
                    window.location.hash = tmp
                    el.id = tmp
                }
            })
            if (!active) {
                document.querySelectorAll('.timeline a').forEach((el)=>{
                    el.classList.remove('active')
                })
            }
        })

        let hash = window.location.hash
        if (hash) hash = hash.replace("#", '')

        if (document.querySelector(`[id='${hash}']`)) {
            this.scrolling(document.querySelector(`[id='${hash}']`))
        }
    },
    methods: {
        scrolling(index) {
            let elementPosition
            if (Number.isInteger(index)) {
                let el = document.querySelectorAll('.element')[index]
                elementPosition = el.getBoundingClientRect()
                el.id = `tmp`
                window.location.hash = this.datas[index].date
                el.id = this.datas[index].date
            } else {
                elementPosition = index.getBoundingClientRect()
            }
            if (window.innerWidth >= 900) {
                scrollTo(0, (window.scrollY + elementPosition.y) - (elementPosition.height/2))
            } else {
                scrollTo(0, (window.scrollY + elementPosition.y))
            }
        }
    }
}
</script>

<style lang="scss">
    .timeline {
        z-index: 2;
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;

        ul {
            li {
                margin: 25px 0;
                a {
                    position: relative;
                    padding-left: 30px;
                    font-size: 0.7em;
                    font-weight: bold;
                    opacity: 0;

                    transition: .3s;
                    .line {
                        position: absolute;
                        height: 2px;
                        width: 10px;
                        left: 0;
                        top: 50%;
                        background: black;

                        transition: .3s;

                        &.hide {
                            width: 0px;
                        }
                    }

                    .date {
                        color: black;
                    }

                    @media (max-width: 680px) {
                        display: block;
                        text-align: center;
                    }

                    &.active {

                        .date {
                            color: $orange;
                        }

                        .line {
                            background: $orange;
                            width: 25px;
                        }
                    }
                }
            }
        }
        @media (max-width: 900px) {
            top: auto;
            bottom: 0;
            height: auto;
            width: 100%;

            ul {
                width: 100%;
                display: flex;
                justify-content: space-around;

                li {
                    width: 15px;

                    a {
                        transform: rotate(-90deg);

                        .date {
                            display: block;
                            width: max-content;
                        }
                    }
                }
            }
        }
    }
</style>