<template>
  <Loading/>
  <Timeline/>
  <BubblesBackground/>
  <HeaderSection :year="year"/>
  <ThreeNavigation/>
  <FooterSection/>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Loading from "@/components/Loading";
import BubblesBackground from "@/components/BubblesBackground";
import Timeline from "@/components/Timeline";

import HeaderSection from "@/sections/HeaderSection";
//import ContentSection from "@/sections/ContentSection";
 import ThreeNavigation from "@/sections/ThreeNavigation"
 import FooterSection from "@/sections/FooterSection"

export default {
  name: "App",
  components: {Loading, HeaderSection, BubblesBackground, ThreeNavigation, Timeline, FooterSection},
  data() {
    return {
      year: 0
    }
  },
  mounted() {
    let isLoaded = false
    window.addEventListener('load', () => {
      isLoaded = true
      document.querySelector('#loading').classList.add('remove')
    })

    window.onbeforeunload = function () {
      //window.scrollTo(0, 0);
    }
    history.scrollRestoration = 'manual';

    //Intro
   // document.body.style.overflow = 'hidden';
   if (window.scrollY == 0) {
     let introTlFrom = {opacity: 0, y: 50}
     let introTlTo = {opacity: 1, y: 0, duration: 3}
     let introTl = gsap.timeline()
     introTl.fromTo('.logo', introTlFrom, introTlTo)
     introTl.to(this, {year: 25, roundProps: 'year', duration: 3}, 0)
     introTl.to('.logo', {scale: 0.7, y: '-15%', duration: 3})
     introTl.fromTo('.intro__text', {opacity: 0, y: 50, x: '-50%'}, {opacity: 1, y: 0, x: '-50%', duration: 3}, "<0")
     introTl.eventCallback('onComplete', () => {
       //let introTl2 = gsap.timeline()
       //document.body.style.overflowY = 'auto';
       //document.body.style.overflowX = 'hidden';
       document.querySelectorAll('.timeline a .line').forEach(el => {
         el.classList.remove('hide')
       })
       gsap.to('.timeline a', {opacity: 1, duration: 0})
       if (!isLoaded) {
         document.querySelector('#loading').classList.remove('remove')
         setTimeout(()=>{
           document.querySelector('#loading').style.display = 'none'
         }, 300)
       }
     })
   } else {
     gsap.to('.logo', {opacity: 1, duration: 1})
     gsap.fromTo('.intro__text', {opacity: 0, y: 50, x: '-50%'}, {opacity: 1, y: 0, x: '-50%', duration: 3}, "<0")
    document.querySelectorAll('.timeline a .line').forEach(el => {
      el.classList.remove('hide')
    })
    if (!isLoaded) {
      document.querySelector('#loading').classList.remove('remove')
    }
    gsap.to('.timeline a', {opacity: 1, duration: 0})
   }
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
* {
  backface-visibility: hidden
}
body {
  overflow-x: hidden;
}
#app {
  background: linear-gradient(0deg, #308a8a 0%, #4ba298 100%);
  min-height: 100vh;
  overflow-x: hidden;
  //overflow: hidden;
}
</style>
