export default `uniform float scale;
uniform float shift;
uniform float uTime;
varying vec2 vUv;
void main() {
  vec3 pos = position;
  pos.y = pos.y + ((sin((uTime + uv.x) * 3.1415926535897932384626433832795) * ((shift*2.0) + 0.1) * 5.0) * 0.125);
  pos.z = pos.z + shift * 10.0;
  //pos.y = pos.y + ((sin(uv.x * 3.1415926535897932384626433832795) * shift * 5.0) * 0.125);
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(pos,1.);
}`