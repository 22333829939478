<template>
    <div id="loading" class="remove">
        <p><span>C</span><span>a</span> <span>c</span><span>h</span><span>a</span><span>r</span><span>g</span><span>e</span> <span>;</span><span>)</span></p>
    </div>
</template>

<script>
export default {
    name: 'Loading'
}
</script>

<style lang="scss" scoped>
#loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: $green-dark;

    transition: .3s;
    &.remove {
        opacity: 0;
        pointer-events: none;
    }

    font-size: 2em;
    font-weight: bold;

    span {
      display: inline-block;
      animation: floated ease-in-out infinite both alternate;
      animation-duration: 3s;
    }
    @for $i from 1 through 10 {
        span:nth-child(#{$i}n) {
            transition-duration: .3s;
            animation-delay: #{$i * -0.8}s;
        }
    }
}
</style>