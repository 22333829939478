<template>
    <footer></footer>
</template>

<script>
export default {
    name: 'FooterSection'
}
</script>

<style lang="scss" scoped>
    footer {
        height: 75vh;
    }
</style>