<template>
    <div>
        <div :id="data.date" class='element' :class="{'left' : pos % 2 === 0, 'right' : pos % 2 !== 0, 'show' : show, 'is-playing': isPlaying }" href="#">
            <div class="element-background" :style="'background-image: url('+data.background+')'"></div>
            <div class="element-img-container">
                <img class="element-img" :data-side="pos % 2 == 0 ? 'left':'right'" :src="data.img" />
                <img v-if="data.vignette" class="element-subimage" :src="data.vignette" />
            </div>
            <div class="element-page">
                <div class="border top"></div>
                <div class="border left"></div>
                <div class="border right"></div>
                <div class="border bottom"></div>
                <div class="element-title-date-container">
                    <p class="element-date">{{ data.date }}</p>
                    <p class="element-title">{{ data.title }}</p>
                </div>
                <p class="element-desc">{{ data.desc }}</p>
                <button v-if="data.podcast" class="btn-podcast" @click="togglePlay()">
                    <img class="text" :class="{'hide': isPlaying}" src="@/assets/img/podcast-text.png"/>
                    <img v-if="!isPlaying" class="play" src="@/assets/img/podcast-play.png" alt="Ecouter le podcast"/>
                    <img v-else class="play" src="@/assets/img/podcast-stop.png" alt="Stoper le podcast"/>
                </button>
                <!-- <audio
                    class="element-podcast"
                    v-if="data.podcast"
                    controls
                    :src="data.podcast">
                        Your browser does not support the
                        <code>audio</code> element.
                </audio> -->
                <p class="podcast-is-coming" v-else>
                    Podcast à venir ;)
                </p>
            </div>
        </div>
        <div 
            v-if="data.podcast"
            class="element-podcast"
            :class="{'show': isPlaying}"
            >
            <button class="close" @click="togglePlay()">Fermer</button>
            <div class="text-mask">
                <p>{{data.date}} - {{data.title}} : {{data.desc}}</p>
            </div>
            <audio
                controls
                :src="data.podcast">
                    Your browser does not support the
                    <code>audio</code> element.
            </audio>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
import Utils from "@/utils/Utils"

let scroll = {
    lastscroll : 0,
    topscroll : 0
}

export default {
    name: 'Element',
    props: ['data', 'pos'],
    data: () => {
        return {
            show: false,
            isPlaying : false,
            unseen: true,
            unplayed: true
        }
    },
    methods: {
        changeYear(index) {
            let logo = document.querySelector('.logo .year')
            let year = {val: parseInt(logo.innerHTML)}
            gsap.to(year, {val: this.data.age, roundProps: 'val', duration: 0.5, onUpdate: () => {
                let prefix = year.val < 10 ? '0' : ''
                logo.innerHTML = prefix + year.val
            }})
            let timelineEls = document.querySelectorAll('.timeline a')
            timelineEls.forEach((el)=>{
                el.classList.remove('active')
            })

            timelineEls[index].classList.add('active')
        },
        togglePlay() {
            if (this.unseen) {
                window._paq.push(['trackEvent', 'Podcast', 'Click Podcast', 'Click Podcast ' + this.data.date])
                this.unplayed = true
            }
            this.isPlaying = !this.isPlaying
            console.log(this.$el.querySelector('audio'))
            if (this.isPlaying) {
                this.$el.querySelector('audio').play()
            } else {
                this.$el.querySelector('audio').pause()
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            scroll.topscroll = window.scrollY
            let pos = this.$el.getBoundingClientRect()
            let offset = window.innerHeight * 0.25
            let isShowArea = window.innerHeight - (offset*2)
            let elementCenter = pos.y + (pos.height/2)
            if (elementCenter > offset && elementCenter < (window.innerHeight - offset)) {
                if (this.unseen) {
                     window._paq.push(['trackEvent', 'Scroll', 'Scroll Event', 'Scroll to ' + this.data.date])
                    this.unseen = false
                }
                this.show = true
                this.changeYear(this.pos)
                // offset = 0%
                // window.innerHeight - offset = 100%
                let translate = Utils.map(elementCenter, offset, window.innerHeight - offset, -8, 8)
                let zoom = Utils.map(elementCenter, offset*2, window.innerHeight - offset, 1, 2)
                let opacity = Utils.map(elementCenter, offset*2, window.innerHeight - offset, 1, 0)
                this.$el.querySelector('.element-background').style.transform = 'translateY(' + translate + '%)'
                this.$el.querySelector('.element-img').style.transform = 'scale(' + zoom + ')'
                this.$el.querySelector('.element-img').style.opacity = opacity
            } else {
                this.$el.querySelector('.element-img').style.opacity = 0
                this.show = false
                if (this.data.podcast) {
                    this.isPlaying = false
                    this.$el.querySelector('audio').pause()
                }
            }
        })

        animate()

        function animate() {
            //console.log(scroll.topscroll / scroll.lastscroll)
            scroll.lastscroll = scroll.topscroll
            requestAnimationFrame(animate)
        }
    }
}
</script>

<style lang="scss">
    .element {
        pointer-events: none;
        min-height: 50vh;
        display: block;
        margin-bottom: 30vh;

        overflow: none;

        transition: opacity 1.5s;

        .element-img-container {
            position: relative;
            margin: 0 15vw;
            margin-top: 10vh;
            width: fit-content;

            .element-img {
                opacity: 0;
                width: 30vw;
                height: auto;

                //transition: opacity .3s;
            }

            .element-subimage {
                position: absolute;
                /* left: 0; */
                right: -5vw;
                bottom: -5vw;
                width: 15vw;
                z-index: 1;
                opacity: 0;
                will-change: opacity;
                transition: opacity .3s;
            }
        }

        .element-background {
            position: fixed;
            left: -10%;
            right: -10%;
            top: -10%;
            bottom: -10%;
            background-position: center center;
            background-size: cover;
            opacity: 0;
            filter: brightness(0.2);
            will-change: opacity;
            will-change: transform;

            transition: opacity .3s, transform 1ms;
        }

        .element-page {
            position: fixed;
            opacity: 0;
            flex-direction: column;
            top: 50%;
            transform: translateY(-50%) scale(1.2);
            right: 0%;
            width: 30vw;
            margin: 0 15vw;
            padding: 25px;
            z-index: 1;
            will-change: border;
            will-change: opacity;
            transition: border .3s;

            .btn-podcast {
                position: relative;
                width: 150px;
                height: 81px;
                opacity: 0;
                display: block;
                margin-top: 20px;
                margin-left: auto;
                cursor: pointer;
                background: transparent;
                border: transparent;
                overflow: hidden;
                will-change: opacity;

                transition: opacity .3s;

                img {
                    position: absolute;
                }

                .play {
                    bottom: 0;
                    left: 50%;
                    width: 30px;
                    transform: translateX(-25%);
                }

                .text {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    transform-origin: center 100%;

                    transition: .3s;

                    &.hide {
                        opacity: 0;
                    }
                }
            }

            .podcast-is-coming {
                margin-top: 10px;
            }

            .border {
                position: absolute;
                background: #391011;

                transition: 1.5s;

                &.top, &.bottom {
                    height: 10px;
                    width: 100%;
                    transform: scale(0, 1);
                }

                &.left, &.right {
                    width: 10px;
                    height: 100%;
                    transform: scale(1, 0);
                }

                &.top {
                    top: -10px;
                    left: 0;
                    transform-origin: left center;
                }

                &.bottom {
                    bottom: -10px;
                    left: 0;
                    transform-origin: right center;
                }

                &.left {
                    top: 0;
                    left: 0;
                    transform-origin: center top;
                }

                &.right {
                    top: 0;
                    right: 0;
                    transform-origin: center bottom;
                }
            }
        }

        .element-title-date-container {
            margin-bottom: 20px;
            .element-date, .element-title {
                color: #391011;
                font-weight: bold;

                span {
                    color: #391011;
                }
            }

            .element-date {
                span {
                    color: $orange;
                }
            }

            .element-title {
                font-size: 2em;
                text-transform: uppercase;

                @media (max-width: 900px) {
                    font-size: 1.5em;
                }
            }
        }

        .element-desc, .element-title, .element-date {
            span {
                opacity: 0;
                transform: translateY(20px);
                will-change: opacity;
                will-change: transform;

                display: inline-block;

                &.space {
                    width: 2vw;
                }
            }
            @for $i from 1 through 10 {
                span:nth-child(#{$i}n) {
                    transition-duration: .5s;
                    transition-delay: #{$i * 0.05}s;
                }
            }
        }

        .element-desc {
            font-size: 1em;
        }

        &.right {
            .element-img-container {
                margin-left: auto;
            }
            .element-page {
                left: 0%;
            }
        }
        &.show {
            pointer-events: initial;
            .element-img-container {
                .element-img{
                    //transform: scale(1.2);
                    opacity: 1;
                }

                .element-subimage {
                    opacity: 1;
                }
            }

            .element-background {
                opacity: 0.3;
            }

            .element-page {
                opacity: 1;
                //TODO
                .border {

                    &.top, &.bottom {
                        transform: scale(1, 1);
                    }

                    &.left, &.right {
                        transform: scale(1, 1);
                    }
                }
            }
            .element-title, .element-desc, .element-date {
                 span {
                     opacity: 1;
                     transform: translateY(0px);
                 }
            }
            .btn-podcast {
                opacity: 1;

                .text {
                    animation: rotate 7s linear infinite both;
                }
            }
        }

        @media (max-width: 900px) {
            .element-page {
                position: relative;
                top: inherit;
                transform: inherit;
                right: inherit;
                width: inherit;
                margin: 0 10px;
            }

            .element-img-container {
                margin: 4vh auto;
                .element-img {
                    width: 50vw;
                }

                .element-subimage {
                    width: 25vw;
                }
            }

            &.right {
                .element-img {
                    margin-left: auto;
                }
                .element-page {
                    left: inherit;
                }
            }
        }

        &.is-playing {
            opacity: .2;
            z-index: 100;
            position: relative;
        }
    }

    .element-podcast {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #0d6161;
        z-index: 101;

        transform: translateY(100%);
        opacity: 0;

        transition: .5s;

        audio {
            margin: 10px;
            width: 100%;
        }

        .close {
            margin: 0px 10px;
            margin-top: 0;
            background: $orange;
            border: none;
            cursor: pointer;
            transform: translateY(-50%);
        }

        .text-mask {
            margin: 10px;
            overflow: hidden;
            border-radius: 10px;
            p {
                width: max-content;
                animation: none;
            }
        }

        &.show {
            transform: translateY(0%);
            opacity: 1;

            p {
                animation: texte-slide 60s linear infinite;
            }
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes texte-slide {
        from {
            transform: translateX(calc(0% + 100vw));
        }
        to {
            transform: translateX(-100%);
        }
    }
</style>