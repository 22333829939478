export default {
    map(value, x1, y1, x2, y2) {
        return (value - x1) * (y2 - x2) / (y1 - x1) + x2
    },

    mapInversed(value, x1, y1) {
        if (value < y1) {
            return this.map(value, x1, y1, 1, 0)
        }
        return this.map(value, y1, document.body.scrollHeight, 0, 1)
    },

    spanSplit(html) {
        let finalHtml = ''
        let word = '<span>'
        // for(let i = 0; i < html.length; i++) {
        //     html[i] !== ' ' ? finalHtml += `<span>${html[i]}</span>` : finalHtml += `<span class="space"></span>`
        // }
        for(let i = 0; i < html.length; i++) {
            if (html[i] !== ' ' && i !== html.length - 1) {
                word += html[i]
            } else if (html[i] == ' ') {
                word += '</span> '
                finalHtml += word
                word = '<span>'
            } else if (i == html.length - 1) {
                word += html[i] + '</span> '
                finalHtml += word
            }
        }
        return finalHtml
    },

    spanSplitLetter(html) {
        let finalHtml = ''
        let word = '<span>'
        for(let i = 0; i < html.length; i++) {
            html[i] !== ' ' ? finalHtml += `<span>${html[i]}</span>` : finalHtml += ` `
        }
        // for(let i = 0; i < html.length; i++) {
        //     if (html[i] !== ' ' && i !== html.length - 1) {
        //         word += html[i]
        //     } else if (html[i] == ' ') {
        //         word += '</span> '
        //         finalHtml += word
        //         word = '<span>'
        //     } else if (i == html.length - 1) {
        //         word += html[i] + '</span> '
        //         finalHtml += word
        //     }
        // }
        return finalHtml
    }
}