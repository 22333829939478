<template>
  <section class="intro">
      <Logo :year="year"/>
      <p class="intro__text">25 ans... Ça grandit vite un Hippocampe ! Il s’en est passé des choses, dis-donc. Ce qui ne change pas : nos valeurs, notre passion web et notre bonne humeur. Alors installez-vous confortablement et embarquez dans l’histoire de notre agence au fil des ans...</p>
  </section>
</template>

<script>
import Logo from "@/components/Logo";

export default {
    name: 'HeaderSection',
    props: ['year'],
    components: {Logo}
}
</script>

<style lang="scss">
    .intro {
        position: relative;
        width: 100%;
        height: 100vh;

        &__text {
            position: absolute;
            bottom: 20%;
            left: 50%;
            opacity: 0;
            margin: 10px;
            max-width: 800px;
            font-size: 1.2em;

            @media (max-width: 600px) {
                margin: 0;
                width: 100%;
                padding: 10px;
            }
        }
    }
</style>