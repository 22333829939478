import bg1 from './assets/img/1990bg-min.jpg'
import bg2 from './assets/img/1995bg-min.jpg'
import bg3 from './assets/img/1998bg-min.jpg'
import bg4 from './assets/img/2001bg-min.jpg'
import bg5 from './assets/img/2005bg-min.jpg'
import bg6 from './assets/img/2011bg-min.jpg'
import bg7 from './assets/img/2017bg-min.jpg'
import bg8 from './assets/img/2020bg-min.jpg'

import img1 from './assets/img/1990-min.jpg'
import img2 from './assets/img/1995-min.jpg'
import img3 from './assets/img/1998-min.jpg'
import img4 from './assets/img/2001-min.jpg'
import img5 from './assets/img/2005-min.jpg'
import img6 from './assets/img/2011-min.jpg'
import img7 from './assets/img/2017-min.jpg'
import img8 from './assets/img/2020.gif'

import vignette1 from './assets/img/vignette1990.png'
import vignette2 from './assets/img/vignette1995.png'
import vignette3 from './assets/img/vignette1998.png'
import vignette4 from './assets/img/vignette2001.png'
import vignette5 from './assets/img/vignette2005.png'

import podcast1 from './assets/podcast/1990.mp3'
import podcast2 from './assets/podcast/1995.mp3'
import podcast3 from './assets/podcast/1998.mp3'
import podcast4 from './assets/podcast/2001.mp3'
import podcast5 from './assets/podcast/2005.mp3'
import podcast6 from './assets/podcast/2011.mp3'
import podcast7 from './assets/podcast/2017.mp3'
import podcast8 from './assets/podcast/2020.mp3'

let datas = [
    {
        date: '1990-1994',
        title: 'L’idée nous trotte dans la tête',
        img: img1,
        background: bg1,
        podcast: podcast1,
        vignette: vignette1,
        desc: `Après une expérience passionnante, mais humainement éprouvante chez France Télécom, Pascale s’est promis de ne jamais oublier cette expérience, si elle créait sa boîte. D’une entreprise qui traite ses employé(e)s comme des pions à une agence avec une gestion humaine de ses collaborateurs : comment les mentalités ont évolué vers un management plus éthique et bienveillant ?`,
        age: 0
    },
    {
        date: '1995',
        title: 'La création d’Hippocampe',
        img: img2,
        background: bg2,
        podcast: podcast2,
        vignette: vignette2,
        desc: `Hippocampe fait ses premiers pas ! Alors qu’internet se développe à peine, nos deux cofondateurs sont pris pour des farfelus. La création d’une agence web aux prémices d’internet, pari fou… ou visionnaire ? (Bon, l’histoire est déjà spoilée...).`,
        age: 0
    },
    {
        date : "1998-2000",
        title: 'La ruée vers internet',
        img: img3,
        background: bg3,
        podcast: podcast3,
        vignette: vignette3,
        desc: `Avec le développement d’internet, on a vu fleurir une multitude d’entreprises dans le web. De grosses agences, des plus petites, des futurs GAFA, mais aussi des projets qui ne tenaient pas la route… Une véritable ruée vers l’or digital ! Entre projets tarabiscotés et pépites innovantes, quel a été l’impact de l’explosion du web sur les entreprises ?`,
        age: 3
    },
    {
        date : "2001-2004",
        title: 'Changement de vitesse !',
        img: img4,
        background: bg4,
        podcast: podcast4,
        vignette: vignette4,
        desc: `Avec l’arrivée de notre nouvel associé Jean en 2001, notre force commerciale fait un grand bond en avant. Plus de structure, une vraie gestion de la relation client et une nouvelle dimension : la communication. On se rend encore plus compte à quel point il est essentiel de chouchouter nos clients. On le faisait, mais là on passe à la vitesse supérieure.`,
        age: 6
    },
    {
        date : "2005-2010",
        title: 'À la recherche de marques',
        img: img5,
        background: bg5,
        podcast: podcast5,
        vignette: vignette5,
        desc: `Entre le début des réseaux sociaux et l’arrivée de Mathieu, comme nouvel associé, ça a été un vrai bouleversement au niveau de la communication de l’agence. Une com moins corpo et plus tournée vers les marques. C’est là que l’on a commencé à réellement se positionner en tant qu’agence digitale… Et communiquer dessus !`,
        age: 10
    },
    {
        date : "2011-2016",
        title: 'La période trouble',
        img: img6,
        background: bg6,
        podcast: podcast6,
        desc: `Une période compliquée avec la disparition de notre ami et associé Jean en 2011. Puis la perte de notre plus gros client en 2016, suivie du départ de Mathieu la même année. L’agence se retrouve fragilisée et déséquilibrée, mais continue à faire face en se recentrant sur ses valeurs. Ou comment garder la tête hors de l’eau en temps de crise…`,
        age: 16
    },
    {
        date : '2017-2019',
        title: 'La quête de sens',
        img: img7,
        background: bg7,
        podcast: podcast7,
        desc: `Après la claque de 2016, on décide de tout remettre en question. Qu’est-ce qui fait sens pour nous aujourd’hui ? Qu’est-ce qui nous motive ? Eh hop c’est reparti, on remet tout à neuf ! Un nouveau positionnement, un nouveau site, une nouvelle équipe. Pour remonter la pente, on a rebondi sur nos valeurs et le sens que l’on veut donner à nos métiers.`,
        age: 20
    },
    {
        date : '2020...',
        title: 'Les débuts de notre réflexion éthique',
        img: img8,
        background: bg8,
        podcast: podcast8,
        desc: `2020, c’est le tournant éthique pour l’agence. Dire stop pour se mettre en phase et agir avec ce qu'il se passe dans le monde. Aller au-delà de la simple prestation digitale. Voilà ce que notre équipe a décidé. Nous, on veut faire du digital éthique : éco-responsable, accessible, et surtout humain ! Maintenant y’a plus qu’à...”`,
        age: 25
    }
]

export default datas