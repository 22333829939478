import {PlaneBufferGeometry, ShaderMaterial, TextureLoader, Mesh, DoubleSide, NearestFilter, Vector3, Color} from 'three'

import vertexShader from '../assets/glsl/vertex2'
import fragmentShader from '../assets/glsl/fragment2'

const textureLoader = new TextureLoader()
let material = new ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
        textureCustom: { value: null },
        uTime: { value: 0.0 },
        scale: { value: 0 },
        shift: { value: 0 },
        opacity: { value: 1 },
      },
    wireframe: false,
    side: DoubleSide
});
let geometry = new PlaneBufferGeometry(1, 1, 16, 16);

export default class Image {
    constructor(el) {
        this.el = el
        this.src = el.src
        this.dimensions = el.getBoundingClientRect();
        this.texture = textureLoader.load(this.src)
        this.texture.minFilter = NearestFilter
        this.texture.magFilter = NearestFilter
        this.texture.generateMipmaps = null

        this.initThree()
    }

    initThree() {
        
        this.geometry = geometry
        // this.material = new ShaderMaterial({
        //     vertexShader,
        //     fragmentShader,
        //     uniforms: {
        //         uTime: { value: 0.0 },
        //         uTexture: { value:  this.texture},
        //         mousePosition: { value:  new Vector3(0.0, 0.0, 0.0)}
        //     },
        //     wireframe: false,
        //     side: DoubleSide
        // });
        this.material = material.clone()
        this.material.uniforms.textureCustom.value = this.texture

        this.mesh = new Mesh(this.geometry, this.material);
        if (this.el.dataset.side == 'left') {
           // this.mesh.rotation.y = -0.6
        } else {
           // this.mesh.rotation.y = 0.6
        }
    }

    position(value, orientation) {
        let a = 0
        let b, c, d
        if (orientation == 'y') {
            b = this.DOM.height
            c = 0.5 - (this.mesh.scale.y / 2)
            d = -0.5 - (this.mesh.scale.y / 2)
        } else {
            let vw = this.visibleWidthAtZDepth(0, this.camera)
            b = this.DOM.width
            c = -vw / 2 + (this.mesh.scale.x / 2)
            d = (vw / 2) + (this.mesh.scale.x / 2)
        }
        return ((value - a) * (d - c)) / (b - a) + c
    }

    size(value) {
        return value / this.DOM.height
    }

    visibleHeightAtZDepth( depth, camera ) {
        // compensate for cameras not positioned at z=0
        const cameraOffset = camera.position.z;
        if ( depth < cameraOffset ) depth -= cameraOffset;
        else depth += cameraOffset;
      
        // vertical fov in radians
        const vFOV = camera.fov * Math.PI / 180; 
      
        // Math.abs to ensure the result is always positive
        return 2 * Math.tan( vFOV / 2 ) * Math.abs( depth );
      };
      
      visibleWidthAtZDepth ( depth, camera ) {
        const height = this.visibleHeightAtZDepth( depth, camera );
        return height * camera.aspect;
      };

    updatePos(el, DOM, camera) {
        this.DOM = DOM
        this.camera = camera
        this.dimensions = el.getBoundingClientRect()
        this.dimensions.x += window.scrollX
        this.dimensions.y += window.scrollY

        this.mesh.scale.set(this.size(this.dimensions.width),this.size(this.dimensions.height),0)
        this.mesh.position.set(this.position(this.dimensions.x, 'x'),this.position(this.dimensions.y, 'y'),0)
    }
}