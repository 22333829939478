<template>
  <canvas id="bubbles-bg"></canvas>
</template>

<script>
//import gsap from "gsap";

export default {
  name: "BubblesBackground",
  mounted() {
    class Bubble {
      constructor(x, r) {
        this.x = x;
        this.y = window.innerHeight;
        this.r = r;
        this.velocity = r * 0.1;
      }

      update(delta) {
        if (this.y > window.innerHeight) {
          this.y = 1
        }
        this.y -= this.velocity * delta;
        this.x += Math.sin((Date.now() + (this.velocity * 500 * this.r)) * 0.001) * (this.velocity);
        this.draw();
      }

      draw() {
        ctx.beginPath();
        ctx.fillStyle = null;
        ctx.strokeStyle = "white";
        ctx.strokeWidth = 2;
        ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
        ctx.stroke();
      }
    }
    let actualScroll = window.scrollY
    let c = document.querySelector("#bubbles-bg");
    let ctx = c.getContext("2d");
    c.width = window.innerWidth;
    c.height = window.innerHeight;

    let bubbles = [];

    for (let i = 0; i < 10; i++) {
      createBubble()
    }

    animBubbles();

    function animBubbles() {
      ctx.clearRect(0, 0, c.width, c.height);
      bubbles.forEach(bubble => {
        bubble.update(1)
        if (bubble.y < 0) {
          bubbles.splice(bubbles.indexOf(bubble), 1)
          createBubble()
        }
      });

      requestAnimationFrame(animBubbles);
    }

    function createBubble() {
      let x = Math.floor(Math.random() * window.innerWidth);
      let r = Math.floor(Math.random() * 10 + 3) + 3;

      bubbles.push(new Bubble(x, r));
    }

    window.addEventListener('resize', () => {
      c.width = window.innerWidth
      c.height = window.innerHeight
    })

    window.addEventListener('scroll', () => {
        bubbles.forEach(bubble => {
          bubble.update((window.scrollY - actualScroll) * 0.4)
        })
        actualScroll = window.scrollY
    })
  }
};
</script>

<style lang="scss">
#bubbles-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
}
</style>
